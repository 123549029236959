export const CONST_VALUES = {
  ssourlDevsso_aperture:
    "https://higdev01.oktapreview.com/app/higdev01_aperturedevdocker_1/exkxe05igujidcdQi0h7/sso/saml",
  ssourlDevsso:
    "https://auth-stage.hitachivantara.com/samlp/6b8NjgQMr2K2ES6XoPACs3dKJtNXYXwa",
  ssourlDevsso1:
    "https://dev-5140120.okta.com/app/dev-5140120_apr_1/exk7bmo55v9k2xiZA5d6/sso/saml",
  ssourlUat:
    "https://auth-stage.hitachivantara.com/samlp/n5hq0fg7VlNyjkyKjrv1NFrk9Tgxiy2u",
  ssourlUat2:
    "https://auth-stage.hitachivantara.com/samlp/ht6AbZTWMjRKnTylQTS9g98zDh2uy7ph",
  ssourlProd:
    "https://hitachivantara.okta.com/app/hitachivantara_hitachiopscenterclearsight_1/exkcuj226xJtdV3Th2p7/sso/saml",
  devsso: [
    "clearsite.hitachivantara-dev.com",
    "clearsight.hitachivantara-dev.com"
  ],
  valid_hostnames: [
    "hitachivantara.com",
    "hitachivantara-dev.com",
    "hitachivantara-uat.com",
    "localhost",
    "higdev01.oktapreview.com",
    "dev-5140120.okta.com",
    "hitachivantara.okta.com",
    "auth-stage.hitachivantara.com",
    "hitachivantara-staging.fluidtopics.net"
  ],
  invalid_chars: ["<script", "%3Cscript", "&lt;script"],
  devsso1: "localhost",
  uat: [
    "clearsite.hitachivantara-uat.com",
    "clearsight.hitachivantara-uat.com"
  ],
  prod: ["clearsite.hitachivantara.com", "clearsight.hitachivantara.com"],
  uat2: [
    "clearsite2.hitachivantara-uat.com",
    "clearsight2.hitachivantara-uat.com"
  ],
  error_contact_admin: "Error Contact Admin",
  PARTNER: "PARTNER",
  CUSTOMER: "CUSTOMER",
  INTUSER: "INTUSER",
  SUCCESS: "success",
  FAILURE: "failure",
  ERROR: "error",
  FAILED: "Failed",
  Error: "Error",
  NO_FAVOURITE_ASSET: "No favorites",
  Warning: "Warning",
  NOT_ENOUGH_DATA_COLLECTED_YET: "Not enough data collected yet",
  fmd: "fmd",
  hdd: "hdd",
  ssd: "ssd",
  nvme: "nvme",
  HSAE: "hsae",
  NAS_Manager: "nas_manager",
  VSSB_Manager: "vssb_manager",
  Pegasus_Manager: "pegasus_manager",
  Asset_Revoked: "Asset_Revoked",
  Asset_Not_Assigned: "Asset_Not_Assigned",
  SERIAL_ID: "Serial Id",
  CUSTOMER_NAME: "Customer Name",
  LOCATION: "Location",
  MODEL: "Model",
  SITE_ID: "Site Id",
  SITE_NAME: "Site Name",
  VERSION: "Version",
  MICROCODE: "Microcode",
  SUPPORT_EXPIRES_IN: "Current Support Expiration",
  SUPPORT_LEVEL: "Current Support Level",
  ENTITLEMENT_START_DATE: "Start Date",
  ENTITLEMENT_END_DATE: "End Date",
  TERM: "Term (Days)",
  CLUSTER: "Cluster/Node",
  LUN_DISTRIBUTION: "LUN Distribution",
  HOST_GROUP: "Host Groups",
  asp_supported: "asp supported",

  google_analytics: {
    Redirected_From: "Redirected_From",
    Redirection: "Redirection",
    Analytics: "Analytics",
    Favorites: "Favorites",
    Inventory: "Inventory",
    Security: "Security",
    Sustainability: "Sustainability",
    Asset_Details: "Asset_Details",
    Permissions: "Permissions",
    Asset_Report: "Asset_Report",
    Asset_Report_PDF: "Asset_Report_PDF",
    PermissionSlider: "PermissionSlider",
    Eula: "Eula",
    Alerts: "Alerts",
    Sustainability_Dashboard: "Sustainability Dashboard",

    Global: "Global",
    Logout: "Logout",
    Logout_Clicked: "Logout_Clicked",
    Login: "Login",
    User_Logged_In: "User_Logged_In",

    Default: "Default",

    Charts: "Charts",
    Analytics_Closest_Capacity_Bar: "Analytics_Closest_Capacity_Bar",
    Analytics_Closest_Temperature_Bar: "Analytics_Closest_Temperature_Bar",
    Asset_Details_Used_Available_Usable: "Asset_Details_Used_Allocated",
    Asset_Details_Used_Capacity: "Asset_Details_Used_Capacity",
    Asset_Details_Processor_Busy_Rate: "Asset_Details_Processor_Busy_Rate",
    Asset_Details_Cache_Usage_Rate: "Asset_Details_Cache_Usage_Rate",
    Asset_Details_Cache_Write_Pending_Rate:
      "Asset_Details_Cache_Write_Pending_Rate",
    Asset_Details_RESPONSE_TIME: "Asset_Details_RESPONSE_TIME",
    Asset_Details_IOPS: "Asset_Details_IOPS",
    Asset_Details_TRANSFER_RATE: "Asset_Details_TRANSFER_RATE",
    Asset_Details_Temperature: "Asset_Details_Temperature",
    Asset_Details_Power_Consumption: "Asset_Details_Power_Consumption",
    Search: "Search",
    Inventory_Search: "Inventory_Search",
    Permission_Search: "Permission_Search",
    Asset_Details_Popup: "Asset_Details_Popup",
    Sort: "Sort",
    Pagination: "Pagination",
    Unselect_All_Clicked: "Unselect_All_Clicked",
    Unselect_All_Button: "Unselect_All_Button",
    Page_Load: "Page_Load",
    Site_Selector: "Site_Selector",

    Site_Details_Display_Type: "Site_Details_Display_Type",
    Inventory_Card: "Inventory_Card",
    Card: "Card",
    Card_View_Button_Clicked: "Card_View_Button_Clicked",
    Inventory_Table: "Inventory_Table",
    Table: "Table",
    Table_View_Button_Clicked: "Table_View_Button_Clicked",

    Add_Permission_Button: "Add_Permission_Button",
    Inventory_Card_Share_Clicked: "Inventory_Card_Share_Clicked",
    Inventory_Table_Share_Clicked: "Inventory_Table_Share_Clicked",
    Asset_Details_Share_Clicked: "Asset_Details_Share_Clicked",
    Permissions_Share_Clicked: "Permissions_Share_Clicked",

    Edit_Permission_Button: "Edit_Permission_Button",
    Permissions_Edit_Clicked: "Permissions_Edit_Clicked",

    Delete_Permission_Button: "Delete_Permission_Button",
    Permissions_Delete_Clicked: "Permissions_Delete_Clicked",
    Permissions_Delete_Yes_Clicked: "Permissions_Delete_Yes_Clicked",
    Permissions_Delete_No_Clicked: "Permissions_Delete_No_Clicked",

    Permission_Slider: "Permission_Slider",
    Permissions_Close_Clicked: "Permissions_Close_Clicked",
    Favorite_Clicked: "Favorite_Clicked",
    Favorite_Button: "Favorite_Button",
    Print_Button: "Print_Button",
    Print_Clicked: "Print_Clicked",
    View_Report_Clicked: "View_Report_Clicked",
    View_Report_Button: "View_Report_Button",
    Header: "Header",
    SideNavbar: "SideNavbar",
    Breadcrumb: "Breadcrumb",
    Email_Add_Button_Clicked: "Email_Add_Clicked",
    Permissions_Add: "Permissions_Add",
    Permissions_Edit: "Permissions_Edit",
    Recommendation_View_Button: "Recommendation_View_Button",
    Recommendation_View_Button_Click: "Recommendation_View_Button_Click",
    Bookmark_Button: "Bookmark_Button",
    Bookmark_Button_Click: "Bookmark_Button_Click",
    Recommendation_Tbl_Row_Click: "Recommendation_Tbl_Row_Click",
    Recommendation_Tbl_Row: "Recommendation_Tbl_Row",
    Dismiss_Recommendation_Button: "Dismiss_Recommendation_Button",
    Dismiss_Recommendation_Button_Click: "Dismiss_Recommendation_Button_Click",
    Recommendation_Edit_Button: "Recommendation_Edit_Button",
    Recommendation_Edit_Button_Click: "Recommendation_Edit_Button_Click",

    Recommendation_ManagerUrl_Redirection_Click:
      "Recommendation_ManagerUrl_Redirection_Click",
    Recommendation_ManagerUrl_Redirection:
      "Recommendation_ManagerUrl_Redirection",

    Edit_Sources_Reset_Button: "Edit_Sources_Reset_Button",
    Edit_Sources_Reset_Button_Click: "Edit_Sources_Reset_Button_Click",
    Edit_Sources_Add_Source_Button_Click:
      "Edit_Sources_Add_Source_Button_Click",
    Edit_Sources_Add_Source_Button: "Edit_Sources_Add_Source_Button",

    Edit_Sources_Save_Button_Click: "Edit_Sources_Save_Button_Click",
    Edit_Sources_Save_Button: "Edit_Sources_Save_Button",

    Open_Case_Button_Click: "Open_Case_Button_Click",
    View_Cases_Button_Click: "View_Cases_Button_Click",
    Tech_Bulletin_Button_Click: "Tech_Bulletin_Button_Click",
    Open_Case_Button: "Open_Case_Button",
    View_Cases_Button: "View_Cases_Button",
    Tech_Bulletin_Button: "Tech_Bulletin_Button",
    HSAE_Maintenance_Click: "HSAEMaintenanceClick",
    HSAE_Maintenance_Button: "HSAE_Maintenance_Button",
    HSAE_Maintenance_Cancel_Button: "HSAE_Maintenance_Cancel_Button",
    HSAE_Maintenance_Cancel_Button_Click:
      "HSAE_Maintenance_Cancel_Button_Click",
    HSAE_Maintenance_Confirm_Button: "HSAE_Maintenance_Confirm_Button",
    HSAE_Maintenance_Confirm_Button_Click:
      "HSAE_Maintenance_Confirm_Button_Click",

    NAS_Manager_Click: "NASManagerClick",
    NAS_Manager_Button: "NAS_Manager_Button",
    NAS_Manager_Cancel_Button: "NAS_Manager_Cancel_Button",
    NAS_Manager_Cancel_Button_Click: "NAS_Manager_Cancel_Button_Click",
    NAS_Manager_Confirm_Button: "NAS_Manager_Confirm_Button",
    NAS_Manager_Confirm_Button_Click: "NAS_Manger_Confirm_Button_Click",

    PEGASUS_Manager_Click: "PEGASUSManagerClick",
    PEGASUS_Manager_Button: "PEGASUS_Manager_Button",
    PEGASUS_Manager_Cancel_Button: "PEGASUS_Manager_Cancel_Button",
    PEGASUS_Manager_Cancel_Button_Click: "PEGASUS_Manager_Cancel_Button_Click",
    PEGASUS_Manager_Confirm_Button: "PEGASUS_Manager_Confirm_Button",
    PEGASUS_Manager_Confirm_Button_Click: "PEGASUS_Manger_Confirm_Button_Click",

    See_All_SystemDrive_Button: "See_All_SystemDrive_Button",
    See_All_SystemDrive_Clicked: "See_All_SystemDrive_Clicked",
    See_All_FileSystems_Button: "See_All_FileSystems_Button",
    See_All_FileSystems_Clicked: "See_All_FileSystems_Clicked",

    Eula_Disagree_Button_Click: "Eula_Disagree_Button_Click",
    Eula_Disagree_Button: "Eula_Disagree_Button",
    Eula_Agree_Button_Click: "Eula_Agree_Button_Click",
    Eula_Agree_Button: "Eula_Agree_Button",

    First: "First",
    Back: "Back",
    Next: "Next",
    Last: "Last",

    Add_Asset_Button: "Add_Asset_Button",
    Add_Asset_Clicked: "Add_Asset_Clicked",
    Add_Asset_Slider: "Add_Asset_Slider",
    Serial_Add_Click: "Serial_Add_Click",
    Add_Asset_Slider_Ok_Clicked: "Add_Asset_Slider_Ok_Clicked",
    Add_Asset_Slider_Close_Clicked: "Add_Asset_Slider_Close_Clicked",
    Asset_Checkbox: "Asset_Checkbox",
    Asset_Checkbox_Clicked: "Asset_Checkbox_Clicked",
    Remove_Asset_Button: "Remove_Asset_Button",
    Remove_Asset_Clicked: "Remove_Asset_Clicked",
    Remove_Asset_Cancel_Clicked: "Remove_Asset_Cancel_Clicked",
    Remove_Asset_Yes_Clicked: "Remove_Asset_Yes_Clicked",
    Get_Assets_Report_Button: "Get_Assets_Report_Button",
    Get_Assets_Report_Clicked: "Get_Assets_Report_Clicked",
    Get_Assets_Report_Cancel_Clicked: "Get_Assets_Report_Cancel_Clicked",
    Get_Assets_Report_Yes_Clicked: "Get_Assets_Report_Yes_Clicked",

    Olympus_Slider: "Olympus_Slider",
    Performance_Button: "Performance_Button",
    Performance_Button_Clicked: "Performance_Button_Clicked",
    Get_Approval_Button: "Get_Approval_Button",
    Get_Approval_Button_Clicked: "Get_Approval_Button_Clicked",
    Approval_Button_Clicked: "Approval_Button_Clicked",
    Decline_Button_Clicked: "Decline_Button_Clicked",
    Olympus_Slider_Close_Clicked: "Olympus_Slider_Close_Clicked",

    Cloud_Connection_Status_Refresh_Clicked:
      "Cloud_Connection_Status_Refresh_Clicked",
    Cloud_Connection_Status_Refresh_Button:
      "Cloud_Connection_Status_Refresh_Button",
    Cloud_Connection_Status: "Cloud_Connection_Status",
    Selected_Tab: "Selected_Tab",

    Share_Multiple_Assets_Slider_Next_Clicked:
      "Share_Multiple_Assets_Slider_Next_Clicked",
    Share_Multiple_Assets_Slider_Back_Clicked:
      "Share_Multiple_Assets_Slider_Back_Clicked",
    Share_Multiple_Assets_Slider_Close_Clicked:
      "Share_Multiple_Assets_Slider_Close_Clicked",
    Share_Multiple_Assets_Slider: "Share_Multiple_Assets_Slider",
    Share_Multiple_Asset_Permissions_Button:
      "Share_Multiple_Asset_Permissions_Button",
    Share_Multiple_Asset_Permissions_Clicked:
      "Share_Multiple_Asset_Permissions_Clicked",
    Share_Multiple_Assets_Slider_Share_Clicked:
      "Share_Multiple_Assets_Slider_Share_Clicked",

    Alert_Status_Type: "Alert_Status_Type",
    Analytics_Alerts_Summary_Clicked: "Analytics_Alerts_Summary_Clicked",
    Analytics_Array_Microcode_Clicked: "Analytics_Array_Microcode_Clicked",
    Alert_Table_View_Button_Clicked: "Alert_Table_View_Button_Clicked",
    capacity_history: "Capacity_History"
  },
  hotjar: {
    asset_detail_share_button:
      "asset_detail_share_button_share_assets_slider_click",
    asset_card_share_button:
      "inventory_asset_card_share_button_share_assets_slider_click",
    asset_table_share_button:
      "inventory_asset_table_share_button_share_assets_slider_click",
    permissions_share_button:
      "permissions_share_button_share_assets_slider_click",
    permissions_edit_button:
      "permissions_edit_button_edit_permission_slider_click",
    permissions_delete_yes_button:
      "permissions_delete_yes_button_permissions_click",

    asset_detail_used_available_pool_capacity: "used_available_pool_capacity",
    asset_detail_used_capacity: "used_capacity",
    asset_detail_processor_busy_rate: "processor_busy_rate",
    asset_detail_cache_usage_rate: "cache_usage_rate",
    asset_detail_cache_write_pending_rate: "cache_write_pending_rate",
    asset_detail_response_time: "response_time",
    asset_detail_iops: "iops",
    asset_detail_transfer_rate: "transfer_rate",
    asset_detail_temperature: "temperature",
    asset_detail_power_consumption: "power_consumption",
    asset_detail_operations_per_second: "operation_per_second",
    asset_detail_disk_latency: "disk_latency",
    asset_detail_ethernet_throughput: "ethernet_throughput",
    asset_detail_fibre_throughput: "fibre_throughput",
    asset_detail_hssi_throughput: "hssi_throughput",
    asset_detail_server_utilization: "server_utilization",
    asset_detail_ambient_temperature: "ambient_temperature"
  },
  component_labels: {
    used_capacity: "Used Capacity",
    used_vs_avl: "Used vs. Available",
    used_vs_available_usable_capacity: "Used vs Available Usable Capacity",
    used_vs_installed_usable_capacity: "Used vs Installed Storage Capacity",
    temperature: "Drive Box Temperature",
    vssb_temperature: "CPU Temperature",
    hnas_temperature: "Temperature",
    power: "Power Consumption",
    processor_busy_rate: "Processor - Busy Rate",
    cache_usage_rate: "Cache - Usage Rate",
    cache_write_pending_rate: "Cache - Write Pending Rate",
    data_available: "Data | Available",
    data_used_spare: "Used | Spare",
    data_used_available: "Used | Available",
    data_used_available_nospace: "Used|Available",
    response_time: "Response Time",
    iops: "IOPS",
    transfer_rate: "Transfer Rate",
    compression: "Compression",
    ambientTemperature: "Ambient Temperature",
    baseboardTemperature: "Baseboard Temperature",
    bucketMetrics: "Bucket Metrics",
    server_module_metrics: "Server Module Metrics",
    storageComponents: "Storage Components",
    // hnas performance graphs title
    disk_latency: "Disk Latency",
    ethernet_throughput: "Ethernet Throughput",
    server_utilization: "Server Utilization",
    operations_per_second: "Operations Per Second",
    hssi_throughput: "HSSI Throughput",
    fibre_throughput: "Fibre Throughput",
    capacity_history: "Capacity History",
    primaryStorageNodesCapacitySummary: "Primary Storage Nodes Capacity Summary"
  },
  class_id_names: {
    open_slider: "open-slider",
    display_user_dropdown: "display-user-dropdown",
    display_asset_popup: "display-asset-popup",
    active_slider_tab: "active-slider-tab",
    menu_options: "menu-options"
  },
  raid_800: "RAID800",
  hm_800: "HM800",
  raid_900: "RAID900",
  hm_900: "HM900",
  eula_agreement_text:
    "<h5 class='eula-title'>End User License Agreement</h5><br/><div class='eula-text'><p>End user license agreement text to be prepared here with correct html formatting</p></div>",
  eula_error_text: "<div> <b>Error!! Please contact Administrator</b></div>",
  TiB: "TiB",
  hardware_alert_status: ["Error", "Warning", "To be fixed", "Blocked"],
  graph_axis_keys: {
    avg_busy_rate: "avg_busy_rate",
    avg_usage_rate: "avg_usage_rate",
    avg_write_pending_rate: "avg_write_pending_rate",
    avg_volume_response_time: "avg_volume_response_time",
    avg_volume_iops: "avg_volume_iops",
    avg_volume_transfer_rate: "avg_volume_transfer_rate",
    used: "used"
  },
  unit: {
    percent: "%",
    MiB_s: "MiB/s",
    milliseconds: "Milliseconds",
    Seconds: "Seconds",
    Microseconds: "Microseconds",
    Mb_s: "Mb/s",
    Ops_s: "Operations/second"
  },
  connection_status: {
    performance: "Performance",
    connected: "Connected",
    disconnected: "Disconnected",
    // waiting: "Waiting",
    registering: "Registering",
    not_registered: "Not registered",
    no_access: "No Access",
    // failed: "Failed",
    not_available: "Not available"
  },
  performance_tab: "performance_tab",
  cloud_connection_status: ["Connected", "Disconnected"],
  performance_slider_status: ["Performance", "Failed", "Declined"],
  hide: "hide",
  show: "show",
  entitlement_categories: {
    Performance: "Performance",
    Location: "Location",
    Configuration: "Configuration"
  },
  // approve: "approve",
  // decline: "decline",
  panelType: {
    performance: "performance",
    approval: "approval",
    add_asset: "add asset",
    permission: "permission",
    multiSharing: "multiSharing"
  },
  expiredMicrocodeStatus: ["Critical", "Warning"],
  no_access: "No Access",
  unknown: "Unknown",
  not_available: "Not Available",
  no_active_assets: "No active assets available",
  no_data_available: "No data available",
  last_refreshed: "Last Refresh: ",
  all_sites: "All Sites",
  favourites: "Favorites",
  maintainance_window_graph_message: "Not Available, down for maintenance",
  status_code_503: "503",
  end_of_service_life_notice: "End of Service Life Notice",
  asset_report_lables: {
    drive: "Drive",
    pool_data: "Pools",
    parity_groups: "Parity Groups",
    ddp_groups: "DDP Groups",
    storage_nodes: "Storage Nodes",
    chassis: "Chassis",
    cache: "Cache",
    controller: "Controller",
    port: "Ports",
    storage_controllers: "Controllers",
    ports: "Ports",
    entitlement_data_list: "Entitlement Information",
    end_of_service_life: "End of Service Life",
    node: "Node",
    node_ssd: "Node SSD",
    storage: "Storage",
    backend_switch: "backend Switch",
    storage_component: "Storage Component",
    license: "License",
    enclosure: "Enclosure",
    server_module: "Server Module",
    hnas_nodes: "Nodes",
    span_storage_components: "Span Storage Components",
    hnas_ev_servers: "EV Servers"
  },
  asset_report_groups: [
    "drive",
    "pool_data",
    "parity_groups",
    "chassis",
    "cache",
    "storage_nodes",
    "controller",
    "storage_controllers",
    "port",
    "customer_details",
    "license",
    "node",
    "node_ssd",
    "storage",
    "backend_switch",
    "storage_component",
    "enclosure",
    "server_module",
    "hnas_nodes",
    "hnas_ev_servers",
    "span_storage_components",
    "entitlement_data_list",
    "end_of_service_life"
  ],
  edit: "edit",
  Microcode: "Microcode",
  Temperature: "Temperature",
  Capacity: "Capacity",
  Entitlement: "Entitlement",
  SupportCase: "SupportCase",
  olympus_hiding_short_message:
    "Cloud Connection will no longer be available as of November 1, 2022",
  olympus_hiding_descriptive_messgae:
    "Cloud Connection will no longer be available as of November 1, 2022. Ops Center Clear Sight plans to reintroduce storage performance features in a future release based upon customer feedback. Please use the feedback panel to provide your input on this topic.",

  alert_categories: ["Entitlement", "Microcode"],
  search_placeholder: {
    customer_user: "Serial, Model, Microcode or Support level",
    internal_user: "Customer Name, Serial, Site, Model or Support level",
    partner_user: "Customer Name, Serial, Site, Model or Support level",
    hcp_internal_user:
      "Customer Name,Serial, Site, Model, Version or Support level ",
    hcp_partner_user:
      "Customer Name, Serial, Site, Model, Version or Support level ",
    hcp_customer_user: "Serial, Model, Version or Support level ",
    hnas_customer_user: "Cluster, Model, Version or Support Level",
    hnas_internal_user:
      "Customer Name, Cluster, Model, Version or Support Level",
    hnas_partner_user: "Customer Name, Cluster, Model, Version or Support Level"
  },
  tooltipText:
    "Used capacity includes up to 8% reserve for future database usage",

  non_clickable_microcode_staus: ["GOOD", "UNKNOWN"],
  system_drives: "system_drives",
  files_system: "files_system",
  ev_servers: "ev_servers",
  drives: "Drives",
  drive: "Drive",
  hcp: "hcp",
  hnas: "hnas"
};

export const hcpCompressionData = [
  {
    label: "Object Age",
    value: "> 30 Days"
  },
  {
    label: "Min Object Size",
    value: "10KB"
  },
  {
    label: "% Space Saved",
    value: "0%"
  },
  {
    label: "Total Space Saved",
    value: "0 TiB"
  },
  {
    label: "Number of Objects Compressed",
    value: "0"
  }
];

export const bucket_metrics_columns = [
  "Object Count",
  "Logical Capacity Used",
  "Physical Capacity Used"
];

export const storage_components_columns = ["Name", "Type", "Status"];

export const server_module_metrics_columns = [
  "Number",
  "Start Time",
  "Bytes Read (TiB)",
  "Bytes Written (TiB)",
  "Bytes Linked (TiB)",
  "Bytes Deleted (TiB)",
  "Bytes Commited (TiB)",
  "Objects Read",
  "Objects Written",
  "Objects Linked",
  "Objects Deleted",
  "Objects Commited"
];

export const system_drives_columns = {
  storage_components_columns: [
    {
      id: "serial",
      label: "Serial",
      align: "left"
    },
    {
      id: "type",
      label: "Type",
      align: "left"
    }
  ],
  system_drives_table_columns: [
    {
      id: "sdlabel",
      label: "SD",
      align: "left"
    },
    {
      id: "pool",
      label: "Pool",
      align: "left"
    },
    {
      id: "device_connect_status",
      label: "Device Label",
      align: "left"
    },
    {
      id: "mirror",
      label: "Mirror",
      align: "left"
    },
    {
      id: "rack_label",
      label: "Rack Label",
      align: "left"
    }
  ]
};

export const file_systems_columns = {
  evs_table_columns: [
    { id: "server", label: "EVS", align: "left" },
    { id: "node", label: "Node", align: "left" },
    { id: "id", label: "ID", align: "right" },
    { id: "type", label: "Type", align: "left" },
    { id: "enabled", label: "Enabled", align: "left" },
    { id: "online", label: "Online", align: "left" },
    { id: "no_of_ports", label: "# of Ports", align: "left" },
    { id: "virus_scanner_mode", label: "Virus Scanner Mode", align: "left" }
  ],
  file_system_table_columns: [
    { id: "name", label: "Name", align: "left" },
    { id: "device", label: "Device", align: "left" },
    { id: "evs", label: "EVS", align: "left" },
    { id: "capacity", label: "Capacity", align: "left" },
    { id: "used", label: "Used", align: "left" },
    { id: "used_percent", label: "Used %", align: "left" },
    { id: "warning", label: "Warning", align: "left" },
    { id: "confined", label: "Confined", align: "left" },
    { id: "block_size", label: "Block Size", align: "left" },
    { id: "thin", label: "Thin", align: "left" },
    { id: "deduped", label: "Deduped", align: "center" },
    { id: "deputed_size", label: "Deduped Size", align: "center" },
    { id: "deputed_per", label: "Deduped %", align: "center" },
    { id: "wfs", label: "WFS", align: "left" }
  ]
};

export const columns = {
  evServersColumns: [
    {
      id: "evs",
      label: "EVS",
      align: "left"
    },
    {
      id: "type",
      label: "Type",
      align: "center"
    },
    {
      id: "online",
      label: "Online",
      align: "center"
    },
    {
      id: "virusScannerMode",
      label: "Virus Scanner Mode",
      align: "center"
    },
    {
      id: "id",
      label: "ID",
      align: "left"
    }
  ],
  fileSystemColumns: [
    {
      id: "name",
      label: "Name",
      align: "left"
    },
    {
      id: "device",
      label: "Device",
      align: "left"
    },
    {
      id: "capacity",
      label: "Capacity",
      align: "left"
    },
    {
      id: "Block Size",
      label: "Block Size",
      align: "left"
    },
    {
      id: "Thin",
      label: "Thin",
      align: "left"
    },
    {
      id: "Deduped",
      label: "Deduped",
      align: "left"
    },
    {
      id: "Deduped Size",
      label: "Deduped Size",
      align: "left"
    }
  ],
  storageComponentColumns: [
    {
      id: "serial",
      label: "Serial",
      align: "left"
    },
    {
      id: "type",
      label: "Type",
      align: "left"
    }
  ],
  systemDrivesColumns: [
    {
      id: "sdlabel",
      label: "SD",
      align: "left"
    },
    {
      id: "pool",
      label: "Pool",
      align: "left"
    },
    {
      id: "device_connect_status",
      label: "Device Label",
      align: "left"
    },
    {
      id: "mirror",
      label: "Mirror",
      align: "left"
    },
    {
      id: "rack_label",
      label: "Rack Label",
      align: "left"
    }
  ]
};

export const hnasPerformanceLegends = {
  read_latency: "Read Latency (ms)",
  write_latency: "Write Latency (ms)",
  stripe_write_latency: "Stripe Write Latency (ms)",
  nfs: "NFS",
  sbm_1: "SBM1",
  recevies: "Receives (Mb/s)",
  transmits: "Transmits (Mb/s)"
};

export const COMMON_CONSTANTS = {
  INTERNAL_DRIVE_SUMMERY: "Internal Drive Summary",
  SEE_MISSING_DATES: "See missing dates",
  PERMISSIONS: "Permissions",
  INVENTORY: "Inventory",
  ALERTS: "Alerts",
  PRIMARY_STORAGE: "Primary Storage",
  PHYSICAL_STORAGE: "Physical Storage",
  EXTENDED_STORAGE: "Extended Storage",
  S_SERIES_STORAGE: "S Series Storage",
  CAPACITY_HISTORY: "Capacity History",
  NODES: "Nodes",
  SPANS: "Spans",
  LICENSED_VIRTUAL_STORAGE: "Licensed Virtual Storage",
  AGGREGATE_LICENSED_VIRTUAL_STORAGE: "Aggregate Licensed Virtual Storage",
  AGGREGATE_FILE_SYSTEM_CAPACITY: "Aggregate File System Capacity",
  AGGREGATE_SPAN_CAPACITY: "Aggregate Span Capacity",
  AGGREGATE_CAPACITY: "Aggregate Capacity",
  TOTAL_CAPACITY: "Total Capacity",
  FILE_SYSTEMS_COUNT: "File System Count",
  SPAN_COUNT: "Span Count",
  SD_COUNT: "SD Count",
  STORAGE_COMPONENTS: "Storage Components",
  USED: "Used",
  AVAILABLE: "Available",
  DATA_NOT_AVAILABLE_MSG: "Data not available",
  NO_MATCHES_WERE_FOUND: "No matches were found.",
  YOU_HAVE_NOT_SHARED_PERMISSIONS: "You have not shared any permissions.",
  NO_ALERTS_TO_REPORT: "No alerts to report",
  NO_ALERTS_TO_DISPLAY: "No alerts to display.",
  THIS_EVS_HAS_NO_FS_INFO_AVAILABLE:
    "This EVS has no file systems information available",
  PLEASE_VERIFY_YOUR_SEARCH_TERM_AND_TRY_AGAIN:
    "Please verify your search term and try again.",
  SEE_ALL_FILESYSTEMS: "See All File Systems",
  SEE_ALL_SYSTEM_DRIVES: "See All System Drives",
  MODEL: "Model",
  CLUSTER_SERVER: "Cluster",
  SITE_ID: "Site ID",
  VERSION: "Version",
  SUPPORT_LEVEL: "Current Support Level",
  MODE: "Mode",
  CLUSTER_SIZE: "Cluster Size",
  LOCATION: "Location",
  SUPPORT_EXPIRES_IN: "Current Support Expires In",
  END_OF_SERVICE_LIFE: "End of Service Life",
  SYSTEM_DRIVES: "System Drives",
  EV_SERVERS: "EV Servers",
  FILE_SYSTEMS: "Filesystems",
  OF: "of",
  SALESFORCE_HRO_SITE_ID: "Salesforce / HRO Site ID",
  VIEW_CASES: "View Cases",
  OPEN_CASE: "Open Case",
  ASSET_REPORT: "Asset Report",
  PRODUCT_DETAILS: "Model Details",
  FAVORITE: "Favorite",
  SHARE: "Share",
  INFORMATION: "Information",
  TIB_UNIT: "TiB",
  SYSTEM_DRIVES_COUNT: "System Drives Count",
  TOTAL_SYSTEM_DRIVES: "Total System Drives",
  SHARED: "Shared",
  NOT_APPLICABLE: "Not Applicable",
  NO_ACCESS: "No Access",
  FILE_STORAGE: "File Storage",
  SUSTAINABILITY: "Sustainability",
  SECURITY: "Security",
  SECURITY_DASHBOARD: "Security Dashboard",
  NO_ASSETS_AVAILABLE: "No assets available",
  NO_FAVOURITE_ASSET: "No favorites",
  NO_DATA_AVAILABLE: "No data available",
  SEARCH_PLACEHOLDER: "Search...",
  NA: "NA",
  CREATE_VOLUME: "Create Volume",
  CREATING: "Creating...",
  CLOSE: "Close",
  AVAILABLE_ASSETS: "Available Assets",
  CAPACITY: "Capacity",
  CREATE_VOLUME_MOST_AVAILABLE_CAPACITY_TITLE:
    "The following have the most available capacity. Select where to create volume.",
  CANCEL: "Cancel",
  AVAILABLE_CAPACITY: " Available Capacity",
  DATA_PROTECTION_RAW_CAPACITY: "Raw Capacity",
  DATA_CAPACITY: "Data Capacity",
  DATA_PROTECTION_CAPACITY: "Data Protection Capacity",
  TOTAL_POOL_CAPACITY: "Total Pool Capacity",
  TOTAL: "Total",
  PARITY: "Parity",
  DATA: "Data",
  PARITY_GROUP_CAPACITY: "Parity Group Capacity",
  DATA_DRIVES: "Data Drives",
  PARITY_DRIVES: "Parity Drives",
  PARITY_GROUP_RAW_CAPACITY: "Parity Group Raw Capacity",
  BLOCK_STORAGE: "Block Storage",
  OBJECT_STORAGE: "Object Storage",
  OVERVIEW: "Overview",
  DASHBOARDS: "Dashboards",
  NO_FAVOURITE_ASSETS_SELECTED: "No favorites",
  NO_DATA_fOR_HOST_SECTION:
    "Please select a different Host group to see more data.",
  SITE_ID_SERIAL: "Site ID_Serial #",
  MAINTENANCE_PROGRESS_MSG: "Maintenance in progress.",
  MAINTENANCE_BACK_MSG: "Be back soon.",
  MAINTENANCE_GENERAL_MSG:
    "We are currently performing updates and scheduled maintenance. Please check again later, thank you!",
  MAINTENANCE_UPDATE_MSG: "We will finish 02/29/2024 at 6pm PST (14:00 UTC)",
  MAINTENANCE_SUPPORT_MSG: "Contact support if you need assistance.",
  MAINTENANCE_SCHEDULE_MSG:
    "Ops Center Clear Sight will be undergoing a scheduled maintenance on February 29, 2024. Starting at 5PM PST (13:00 UTC) and ending at 6PM (14:00 UTC). The application will be unavailable during that time.",
  NOT_USING_LATEST_SOFTWARE_VERSION_MSG:
    "This system is not using the latest software version.",
  NOT_USING_LATEST_MICROCODE_VERSION_MSG:
    "This asset is not using the latest microcode."
};
